import HttpRequester from '../utils/http';
import Client from '../models/Client';
import Account from '../models/Account';

class ClientApi extends HttpRequester {

  get(account, id, headers = {}) {
    if (account.constructor === Account) {
      let path = `/account/${account.id}/client`;
      if (id) path.concat(`/${id}`);
      return super.get(path, headers, Client);
    }
    return Promise.reject(new Error('Invalid client object and/or account object'));
  }

  create(client, account, headers = {}) {
    if (client.constructor === Client && account.constructor === Account) {
      return super.post(`/account/${account.id}/client`, Object.assign(headers, {
        'Content-Type': 'application/json; charset=UTF-8'
      }), client.export(), Client);
    }
    return Promise.reject(new Error('Invalid client object and/or account object'));
  }

  update(client, account, headers = {}) {
    if (client.constructor === Client && account.constructor === Account) {
      return super.put(`/account/${account.id}/client/${client.id}`, Object.assign(headers, {
        'Content-Type': 'application/json; charset=UTF-8'
      }), client.export(), Client);
    }
    return Promise.reject(new Error('Invalid client object and/or account object'));
  }

  delete(client, account, headers = {}) {
    if (client.constructor === Client && account.constructor === Account) {
      return super.delete(`/account/${account.id}/client/${client.id}`, headers);
    }
    return Promise.reject(new Error('Invalid client object and/or account object'));
  }
}

export default ClientApi;
