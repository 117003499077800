import BaseModel from './BaseModel';

class User extends BaseModel {

  get name() {
    return this.get('name', null);
  }

  set name(name) {
    this.set('name', name, String, null);
  }

  get accessLevel() {
    return this.get('account_level', null);
  }

  set accessLevel(accessLevel) {
    this.set('account_level', accessLevel, String, null);
  }

  get enabled() {
    return this.get('enabled', null);
  }

  set enabled(enabled) {
    this.set('enabled', enabled, Boolean, null);
  }
}

export default User;
