import HttpRequester from '../utils/http';
import Account from '../models/Account';

class AccountAPI extends HttpRequester {

  default(headers = {}) {
    return super.get('/account/default', headers, Account);
  }

  get(id, headers = {}) {
    let path = '/account';
    if (id) path.concat(`/${id}`);
    return super.get(path, headers, Account);
  }

  getByName(name, headers = {}) {
    return super.get(`/account/name/${name}`, headers, Account);
  }

  create(account, headers = {}) {
    if (account.constructor === Account) {
      return super.post('/account', Object.assign(headers, {
        'Content-Type': 'application/json; charset=UTF-8'
      }), account.export(), Account);
    }
    return Promise.reject(new Error('Invalid account object'));
  }

  update(account, headers = {}) {
    if (account.constructor === Account) {
      return super.put(`/account/${account.id}`, Object.assign(headers, {
        'Content-Type': 'application/json; charset=UTF-8'
      }), account.export(), Account);
    }
    return Promise.reject(new Error('Invalid account object'));
  }

  delete(account, headers = {}) {
    if (account.constructor === Account) {
      return super.delete(`/account/${account.id}`, headers);
    }
    return Promise.reject(new Error('Invalid Account object'));
  }
}

export default AccountAPI;
