import { modelConstants } from '../constants';
import {
  Core,
  SessionAccount
} from '../services';

/**
 * STATES
 */
const beginRequest = (message) => {
  return {
    type: modelConstants.REQUEST_BEGIN,
    message
  }
}

const getSuccess = (models) => {
  return {
    type: modelConstants.GET_SUCCESS,
    models
  }
}

const createSuccess = (model) => {
  return {
    type: modelConstants.CREATE_SUCCESS,
    model
  }
}

const updateSuccess = (model) => {
  return {
    type: modelConstants.UPDATE_SUCCESS,
    model
  }
}

const deleteSuccess = () => {
  return { type: modelConstants.DELETE_SUCCESS }
}

const onFailure = (message) => {
  return {
    type: modelConstants.REQUEST_FAILED,
    message
  }
}

/**
 * METHODS
 */
const Get = (id) => {
  return async (dispatch, getState) => {
    if (id) dispatch(beginRequest('Retrieving Model'));
    else dispatch(beginRequest('Retrieving Models'));
    const state = getState();
    const core = await Core(state).catch(e => dispatch(onFailure(e.message)));
    const account = await SessionAccount(state).catch(e => dispatch(onFailure(e.message)));
    const clients = await core.requests.model.get(account, id).catch(e => dispatch(onFailure(e.message)));
    return dispatch(getSuccess(clients));
  }
}

const Create = (data) => {
  return async (dispatch, getState) => {
    dispatch(beginRequest('Creating Model'));
    const state = getState();
    const core = await Core(state).catch(e => dispatch(onFailure(e.message)));
    const account = await SessionAccount(state).catch(e => dispatch(onFailure(e.message)));
    const newModel = new core.model.client(data);
    const model = await core.requests.model.create(newModel, account).catch(e => dispatch(onFailure(e.message)));
    return dispatch(createSuccess(model));
  }
}

const Update = (data, client) => {
  return async (dispatch, getState) => {
    dispatch(beginRequest('Updating Model'));
    const state = getState();
    const core = await Core(state).catch(e => dispatch(onFailure(e.message)));
    const update = client.update(Object.assign(client.export(), data));
    const account = await SessionAccount(state).catch(e => dispatch(onFailure(e.message)));
    const updateClient = await core.requests.client.update(update, account).catch(e => dispatch(onFailure(e.message)));
    return dispatch(updateSuccess(updateClient));
  }
}

const Delete = (client) => {
  return async (dispatch, getState) => {
    dispatch(beginRequest('Deleting Model'));
    const state = getState();
    const core = await Core(state).catch(e => dispatch(onFailure(e.message)));
    const account = await SessionAccount(state).catch(e => dispatch(onFailure(e.message)));
    await core.requests.client.delete(client, account).catch(e => dispatch(onFailure(e.message)));
    return dispatch(deleteSuccess());
  }
}

export const model = {
  Get,
  Create,
  Update,
  Delete
};
