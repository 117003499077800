import HttpRequester from '../utils/http';

class AuthAPI extends HttpRequester {

  authenticate(obj, headers = {}) {
    let body = [];

    for (let prop in obj) {
      if (obj.hasOwnProperty(prop)) {
        const key = encodeURIComponent(prop);
        const val = encodeURIComponent(obj[prop]);

        body.push(`${key}=${val}`);
      }
    }
    body = body.join('&');
    return super.post('/auth', Object.assign(headers, {
      'Content-Type': 'application/x-www-form-urlencoded'
    }), body);
  }

  authPassword(email, password, clientId) {
    return new Promise(async (resolve, reject) => {
      if (!email) {
        return reject('email required');
      }

      if (!password) {
        return reject('Password required');
      }

      const auth = await this.authenticate({
        client_id: clientId,
        email: email,
        password: password,
        grant_type: 'password'
      }).catch(reject);

      return resolve(auth);
    });
  }

  authToken(clientId, secret) {
    return new Promise(async (resolve, reject) => {
      if (!secret) {
        return reject('token required');
      }

      const auth = this.authenticate({
        client_id: clientId,
        client_secret: secret,
        grant_type: 'client'
      }).catch(reject);

      return resolve(auth);
    });
  }

  refreshToken(token) {
    return new Promise(async (resolve, reject) => {
      if (!token) {
        return reject('token required');
      }

      const auth = this.authenticate({
        refresh_token: token,
        grant_type: 'refresh'
      }).catch(reject);

      return resolve(auth);
    });
  }
}

export default AuthAPI;
