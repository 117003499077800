
const Core = (state) => {
  return new Promise((resolve, reject) => {
    const BLAID = state.BLAID.core;
    if (BLAID) resolve(BLAID);
    else reject(new Error('No BLAID found'));
  });
}

const SessionUser = (state) => {
  return new Promise((resolve, reject) => {
    let user = state.user.default;
    if (user) resolve(user);
    else {
      Core(state)
        .then(core => {
          core.requests.user.default()
            .then(resolve)
            .catch(reject);
        })
        .catch(reject);
    }
  });
}

const SessionAccount = (state) => {
  return new Promise((resolve, reject) => {
    let account = state.account.default;
    if (account) resolve(account);
    else {
      Core(state)
        .then(core => {
          core.requests.account.default()
            .then(resolve)
            .catch(reject);
        })
        .catch(reject);
    }
  });
}

export {
  Core,
  SessionUser,
  SessionAccount
}
