import { modelConstants } from '../constants';

export default (state = {}, action) => {
  const response = { type: action.type }
  switch (action.type) {
    case modelConstants.REQUEST_BEGIN:
      response.message = action.message;
      return Object.assign(state, response);
    case modelConstants.GET_SUCCESS:
      return Object.assign(state, action);
    case modelConstants.CREATE_SUCCESS:
      return Object.assign(state, filterAndReplace(action, state));
    case modelConstants.UPDATE_SUCCESS:
      return Object.assign(state, filterAndReplace(state, action));
    case modelConstants.DELETE_SUCCESS:
      return Object.assign(state, response);;
    case modelConstants.REQUEST_FAILED:
      response.message = action.message;
      return Object.assign(state, response);;
    default:
      return state;
  }
};

const filterAndReplace = (state, action) => {
  if (Array.isArray(action.model)) {
    return {
      type: action.type,
      models: action.model
    };
  } else if (state.model) {
    let replaced = false;
    const models = state.model.map(c => {
      if (c.id === action.model.id) {
        replaced = true;
        return action.model;
      }
      return c;
    });
    if (!replaced) models.push(action.model)
    return {
      type: action.type,
      models: models
    }
  }
  return action;
}
