import React from 'react';
import './Loader.css'

const Loader = () => {
  return (
    <div className="d-flex justify-content-center">
      <div className="spinner-border text-light" role="status">
        <span className="sr-only">Loading...</span>
      </div>
    </div>
  );
}

export default Loader;


// <div className="loader-container">
//   <div id="atomo">
//     <div id="nucleo "></div>
//     <div className="electron a"></div>
//     <div className="electron b"></div>
//     <div className="electron c"></div>
//     <div className="electron d"></div>
//     <div className="electron e"></div>
//     <div className="electron f"></div>
//     <div className="electron g"></div>
//   </div>
// </div>
