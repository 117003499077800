import React from 'react';
import PropTypes from 'prop-types';

const ImageButton = (props) => {
  const _props = Object.assign({}, props);
  delete _props.image;
  delete _props.alt;
  return (
    <button {..._props}>
      <img src={ props.image } alt={ props.alt } />
    </button>
  );
}

ImageButton.propTypes = {
  image: PropTypes.string.isRequired,
  alt: PropTypes.string
}

export default ImageButton;
