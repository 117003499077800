import React, { Component } from 'react';
import { connect } from 'react-redux';
import './AccountsManager.css';
import { user } from '../../../../actions';

class AccountsManager extends Component {
  componentDidMount() {
    this.props.getUser();
  }

  render() {
    return (
      <h1 className='accounts-manager-title'>Accounts Manager</h1>
    );
  }
}

const mapStateToProps = state => ({
  ...state
});

const mapDispatchToProps = {
  getUser: user.Get,
  createUser: user.Create,
  updateUser: user.Update,
  deleteUser: user.Delete,
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountsManager);
