import { alertConstants } from '../constants';

export default (state = {}, action) => {
  if (action.type === alertConstants.SUCCESS || action.type === alertConstants.ERROR) {
    return {
      type: action.type,
      message: action.message
    };
  }
  return {};
};
