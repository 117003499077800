import {
  userConstants
} from '../constants';
import {
  Core,
  SessionUser,
  SessionAccount
} from '../services';

/**
 * STATES
 */
const beginRequest = (message) => {
  return {
    type: userConstants.REQUEST_BEGIN,
    message
  }
}

const profileSuccess = (user) => {
  return {
    type: userConstants.PROFILE_SUCCESS,
    user
  }
}

const getSuccess = (user) => {
  return {
    type: userConstants.GET_SUCCESS,
    user
  }
}

const createSuccess = (user) => {
  return {
    type: userConstants.CREATE_SUCCESS,
    user
  }
}

const updateSuccess = (user) => {
  return {
    type: userConstants.UPDATE_SUCCESS,
    user
  }
}

const deleteSuccess = (user) => {
  return { type: userConstants.DELETE_SUCCESS }
}

const onFailure = (message) => {
  return {
    type: userConstants.REQUEST_FAILURE,
    message
  }
}

/**
 * METHODS
 */

const Session = () => {
  return async (dispatch, getState) => {
    dispatch(beginRequest('Requesting Profile'));
    const state = getState();
    const user = await SessionUser(state).catch(e => dispatch(onFailure(e.message)));
    return dispatch(profileSuccess(user));
  }
}

const Get = (id) => {
  return async (dispatch, getState) => {
    if (id) dispatch(beginRequest('Retrieving User Details'));
    else dispatch(beginRequest('Retrieving User'));
    const state = getState();
    const core = await Core(state).catch(e => dispatch(onFailure(e.message)));
    const account = await SessionAccount(state).catch(e => dispatch(onFailure(e.message)));
    const acc = await core.requests.user.get(id, account).catch(e => dispatch(onFailure(e.message)));
    return dispatch(getSuccess(acc));
  }
}

const Create = (data) => {
  return async (dispatch, getState) => {
    dispatch(beginRequest('Creating User'));
    const state = getState();
    const core = await Core(state).catch(e => dispatch(onFailure(e.message)));
    const account = await SessionAccount(state).catch(e => dispatch(onFailure(e.message)));
    const userData = new core.models.user(data);
    const user = await core.requests.user.create(userData, account).catch(e => dispatch(onFailure(e.message)));
    return dispatch(createSuccess(user));
  }
}

const Update = (data, user) => {
  return async (dispatch, getState) => {
    dispatch(beginRequest('Updating User'));
    const state = getState();
    const core = await Core(state).catch(e => dispatch(onFailure(e.message)));
    const account = await SessionAccount(state).catch(e => dispatch(onFailure(e.message)));
    const update = await user.update(Object.assign(user.export(), data));
    const usr = core.requests.user.update(update, account).catch(e => dispatch(onFailure(e.message)));
    return dispatch(updateSuccess(usr));
  }
}

const Delete = (user) => {
  return async (dispatch, getState) => {
    dispatch(beginRequest('Deleting User'));
    const state = getState();
    const core = await Core(state).catch(e => dispatch(onFailure(e.message)));
    const account = await SessionAccount(state).catch(e => dispatch(onFailure(e.message)));
    await core.requests.user.delete(user, account).catch(e => dispatch(onFailure(e.message)));
    return dispatch(deleteSuccess());
  }
}

export const user = {
  Session,
  Get,
  Create,
  Update,
  Delete
};
