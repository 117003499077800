import { accountConstants } from '../constants';

export default (state = {}, action) => {
  const response = { type: action.type }
  switch (action.type) {
    case accountConstants.REQUEST_BEGIN:
      response.message = action.message;
      return response;
    case accountConstants.SESSION_ACCOUNT_SUCCESS:
      response.session = action.account;
      return response;
    case accountConstants.GET_SUCCESS:
      return Object.assign(state, action);
    case accountConstants.CREATE_SUCCESS:
      return Object.assign(state, filterAndReplace(state, action));
    case accountConstants.UPDATE_SUCCESS:
      return Object.assign(state, filterAndReplace(state, action));
    case accountConstants.DELETE_SUCCESS:
      return response;
    case accountConstants.REQUEST_FAILURE:
      response.message = action.message;
      return response;
    default:
      return state;
  }
};

const filterAndReplace = (state, action) => {
  if (Array.isArray(action.account)) {
    return {
      type: action.type,
      accounts: action.account
    };
  } else if (state.account) {
    let replaced = false;
    const accounts = state.account.map(c => {
      if (c.id === action.account.id) {
        replaced = true;
        return action.account;
      }
      return c;
    });
    if (!replaced) accounts.push(action.account)
    return {
      type: action.type,
      accounts: accounts
    }
  }
  return action;
}
