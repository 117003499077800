import { userConstants } from '../constants';

export default (state = {}, action) => {
  const response = { type: action.type };
  switch (action.type) {
    case userConstants.REQUEST_BEGIN:
      response.message = action.message;
      return response;
    case userConstants.PROFILE_SUCCESS:
      response.session = action.user;
      return response;
    case userConstants.GET_SUCCESS:
      return Object.assign(state, action);
    case userConstants.CREATE_SUCCESS:
      return Object.assign(state, filterAndReplace(state, action));
    case userConstants.UPDATE_SUCCESS:
      return Object.assign(state, filterAndReplace(state, action));
    case userConstants.DELETE_SUCCESS:
      return response;
    case userConstants.REQUEST_FAILURE:
      response.message = action.message;
      return response;
    default:
      return state;
  }
};

const filterAndReplace = (state, action) => {
  if (Array.isArray(action.user)) {
    return {
      type: action.type,
      users: action.user
    };
  } else if (state.users) {
    let replaced = false;
    const users = state.users.map(c => {
      if (c.id === action.user.id) {
        replaced = true;
        return action.user;
      }
      return c;
    });
    if (!replaced) users.push(action.user)
    return {
      type: action.type,
      users: users
    }
  }
  return action;
}
