import HttpRequester from '../utils/http';
import Model from '../models/Model';
import Account from '../models/Account';

class ModelApi extends HttpRequester {

  get(account, id, headers = {}) {
    if (account.constructor === Account) {
      let path = `/account/${account.id}/model`;
      if (id) path.concat(`/${id}`);
      return super.get(path, headers, Model);
    }
    return Promise.reject(new Error('Invalid model object and/or account object'));
  }

  create(model, account, headers = {}) {
    if (Model.constructor === Model && account.constructor === Account) {
      return super.post(`/account/${account.id}/model`, Object.assign(headers, {
        'Content-Type': 'application/json; charset=UTF-8'
      }), model.export(), Model);
    }
    return Promise.reject(new Error('Invalid model object and/or account object'));
  }

  update(model, account, headers = {}) {
    if (Model.constructor === Model && account.constructor === Account) {
      return super.put(`/account/${account.id}/model/${model.id}`, Object.assign(headers, {
        'Content-Type': 'application/json; charset=UTF-8'
      }), model.export(), Model);
    }
    return Promise.reject(new Error('Invalid model object and/or account object'));
  }

  delete(model, account, headers = {}) {
    if (Model.constructor === Model && account.constructor === Account) {
      return super.delete(`/account/${account.id}/model/${model.id}`, headers);
    }
    return Promise.reject(new Error('Invalid model object and/or account object'));
  }
}

export default ModelApi;
