class BaseModel {
  constructor(data = {}) {
    this._ = {};
    this.update(data);
  }

  get(prop, _default) {
    return this._[prop] !== undefined ? this._[prop] : _default;
  }

  set(prop, value, constructor) {
    if (value && value.constructor === constructor) {
      this._[prop] = value;
    }
  }

  update(data = {}) {
    Object.keys(data).forEach(prop => {
      this._[prop] = data[prop];
    });
    return this;
  }

  export() {
    return JSON.parse(JSON.stringify(this._));
  }

  get id() {
    return this.get('id', null);
  }

  set id(id) {
    this.set('id', id, Number, null);
  }

  get externalId() {
    return this.get('external_id', null);
  }

  set externalId(eId) {
    this.set('external_id', eId, String, null);
  }

  get recordHistory() {
    return this.get('record_history', null);
  }

  get createdAt() {
    const record = this.recordHistory;

    return record.created_at !== undefined ? record.created_at : null;
  }

  get createdBy() {
    const record = this.recordHistory;

    return record.created_by !== undefined ? record.created_by : null;
  }

  get createdByType() {
    const record = this.recordHistory;

    return record.created_by_type !== undefined ? record.created_by_type : null;
  }

  get updatedAt() {
    const record = this.recordHistory;

    return record.updated_at !== undefined ? record.updated_at : null;
  }

  get updatedBy() {
    const record = this.recordHistory;

    return record.updated_by !== undefined ? record.updated_by : null;
  }

  get updatedByType() {
    const record = this.recordHistory;

    return record.updated_by_type !== undefined ? record.updated_by_type : null;
  }
}

export default BaseModel;
