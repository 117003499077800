export default [
  {
    title: 'AI Manager',
    color: '#5f99cf',
    path: '/ai-manger'
  },
  {
    title: 'Access Manager',
    color: '#d8734b',
    path: '/access-Manager'
  },
  {
    title: 'Accounts Manager',
    color: '#7869aa',
    path: '/accounts-manager'
  },
  {
    title: 'Documentation',
    color: '#62ae9f',
    path: '/documentation'
  },
  {
    title: 'Support',
    color: '#af5571',
    path: '/support'
  },
];
