import {
  createStore,
  applyMiddleware
} from 'redux';
import thunk from 'redux-thunk';
import { createLogger } from 'redux-logger';
import rootReducer from '../reducers'

const loggerMiddleware = createLogger();

export default (initialState = {}) => {
  return createStore(
    rootReducer,
    initialState,
    applyMiddleware(
      thunk
      // process.env.NODE_ENV === 'development' ? loggerMiddleware : null
    )
  );
}
