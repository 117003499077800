import React from "react";
import "./NotFound.css";

export default () => {
  return (
    <div className="not-found">
      <h1>Sorry, page not found!</h1>
    </div>
  );
}
