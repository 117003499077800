import React, { Component } from 'react';
import { Input } from '../../components';
import './SignIn.css';
import { connect } from 'react-redux';
import {
  authenticate,
  alert
} from '../../../actions';
import {
  Link,
  withRouter
} from 'react-router-dom';

class SignIn extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: {
        email: '',
        password: ''
      }
    };
    this.formElements = this.formElements.bind(this);
    this.onTextChange = this.onTextChange.bind(this);
    this.executeLogin = this.executeLogin.bind(this);
  }

  onTextChange(i, val) {
    const data = this.state.data;
    const key = Object.keys(data)[i]
    data[key] = val;
    this.setState({ data: data });
  }

  executeLogin(e) {
    e.preventDefault();
    const data = this.state.data;
    if (data.email && data.password) {
      this.props.clear();
      return this.props.emailLogin(data.email, data.password);
    } else this.props.error('Email and Password are required.')
  }

  formElements() {
    const data = this.state.data;
    return Object.keys(data).map((d, i) => {
      const className = `form-control ${d}`
      const label = data[d].length > 0 ? <h5>{d}</h5>: null;
      return (
        <div key={i}>
         { label }
         <div className="form-group">
            <Input
            index={i}
            type={d}
            className={className}
            id={d}
            value={data[d]}
            placeholder={d}
            onChange={ this.onTextChange } />
          </div>
        </div>
      );
    })
  }

  render() {
    return (
      <div className='row sign-in'>
        <div className="col-md-4 offset-md-4">
          <form>
            { this.formElements() }
            <p className="register-p">
              <Link to="/register">
                <button className="register-btn">
                  Sign-Up
                </button>
              </Link> for a new account</p>
            <button type="submit" className="sign-in-btn" onClick={ this.executeLogin }>sign-in</button>
          </form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  ...state
});

const mapDispatchToProps = {
  emailLogin: authenticate.emailLogin,
  error: alert.error,
  clear: alert.clear
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SignIn));
