export const pageConstants = {
  SPLASH_PAGE: 'SPLASH_PAGE',
  SIGN_IN_PAGE: 'SIGN_IN_PAGE',
  SIGN_UP_PAGE: 'SIGN_UP_PAGE',

  AI_MANAGER: 'AI_MANAGER',
  ACCESS_MANAGER: 'ACCESS_MANAGER',
  ACCOUNTS_MANAGER: 'ACCOUNTS_MANAGER',
  DOCUMENTATION: 'DOCUMENTATION',
  SUPPORT: 'SUPPORT',
}
