import Account from './Account';
import User from './User';
import Client from './Client';
import Model from './Model';

export default class Models {
  constructor() {
    this.account = Account;
    this.user = User;
    this.client = Client;
    this.model = Model;
  }
}
