import HttpRequester from '../utils/http';
import User from '../models/User';
import Account from '../models/Account';

class UserApi extends HttpRequester {

  default(headers = {}) {
    return super.get(`/user/default`, headers, User);
  }

  get(id, account, headers = {}) {
    if (account.constructor === Account) {
      let path = `/account/${account.id}/user`;
      if (id) path.concat(`/${id}`);
      return super.get(path, headers, User);
    }
    return Promise.reject(new Error('Invalid account object'));
  }

  create(user, account, headers = {}) {
    if (user.constructor === User && account) {
      return super.post(`/account/${account.id}/user`, Object.assign(headers, {
        'Content-Type': 'application/json; charset=UTF-8'
      }), user.export(), User);
    }
    return Promise.reject(new Error('Invalid user object & account'));
  }

  update(user, account, headers = {}) {
    if (user.constructor === User && account) {
      return super.put(`/account/${account.id}/user/${user.id}`, Object.assign(headers, {
        'Content-Type': 'application/json; charset=UTF-8'
      }), user.export(), User);
    }
    return Promise.reject(new Error('Invalid user object & account'));
  }

  delete(user, account, headers = {}) {
    if (user.constructor === User && account) {
      return super.delete(`/account/${account.id}/${user.id}`, headers);
    }
    return Promise.reject(new Error('Invalid user object & account'));
  }
}

export default UserApi;
