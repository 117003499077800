import React, { Component } from 'react';
import { connect } from 'react-redux';
import './AccessManager.css';
import {
  alert,
  client
} from '../../../../actions';

class AccessManager extends Component {
  constructor(props) {
    super(props);
    this.props.getClient();
    this.clientsList = this.clientsList.bind(this);
    this.handleStatusToggle = this.handleStatusToggle.bind(this);
  }

  handleStatusToggle(e, i) {
    e.preventDefault();
    const client = this.props.client.clients[i];
    this.props.updateClient({enabled: !client.enabled}, client);
  }

  clientsList() {
    const clients = this.props.client.clients;
    if (clients) {
      return (
        <div className="list-group">
          {
            clients.map((c, i) => {
              return (
                <div key={i} className="list-group-item list-group-item-action flex-column align-items-start">
                  <div className="d-flex w-100 justify-content-between">
                    <p className="mb-1">{c.name}</p>
                    <small>{c.accessLevel}</small>
                  </div>
                  <p className="mb-1">client ID: {c.id}</p>
                  <p className="mb-1">client secret: {c.secret}</p>
                  <label>{c.enabled ? 'Enabled' : 'Disabled'}</label>
                </div>
              )
            })
          }
        </div>
      )
    }
  }
  // <div className="custom-control custom-switch">
  //   <input type="checkbox"
  //   className="custom-control-input"
  //   id="customSwitch1"
  //   defaultChecked={c.enabled}
  //   onChange={e => this.handleStatusToggle(e, i)}/>
  //   <label className="custom-control-label" for="customSwitch1">Enabled</label>
  // </div>
  render() {
    return (
      <div>
        <h1 className='access-manager-title'>Access Manager</h1>
        { this.clientsList() }
      </div>
    );
  }
}

const mapStateToProps = state => ({
  ...state
});

const mapDispatchToProps = {
  getClient: client.Get,
  createClient: client.Create,
  updateClient: client.Update,
  deleteClient: client.Delete,
  clear: alert.clear
};

export default connect(mapStateToProps, mapDispatchToProps)(AccessManager);
