import React, { Component } from 'react';
import { connect } from 'react-redux';
import { alert } from '../../actions';
import {
  alertConstants,
  pageConstants
} from '../../constants';
import {
  NavBar,
  SideBar,
} from '../components';
import {
  user,
  authenticate
} from '../../actions';
import {
  AiManager,
  AccessManager,
  AccountsManager,
  Documentation,
  Support
} from './content';
import './container.css';
import NotFound from '../not-found';

class Container extends Component {
  constructor(props) {
    super(props);
    this.clearAction = this.clearAction.bind(this);
    this.checkAlert = this.checkAlert.bind(this);
    this.pageContent = this.pageContent.bind(this);
    this.menuClickedItem = this.menuClickedItem.bind(this);

    this.menuSelection = [
      'Settings',
      'Billing',
      'Sign-out'
    ];
  }

  clearAction(e) {
    e.preventDefault();
    this.props.clear();
  }

  componentDidMount() {
    this.props.getSessionUser();
  }

  checkAlert() {
    const alert = this.props.alert;
    if (alert.message) {
      const type = alert.type === alertConstants.SUCCESS ? 'alert-success' : 'alert-danger';
      const alertClass = `alert ${type} alert-dismissible fade show`;
      const className = `col-md-4 offset-md-4 ${alertClass}`;
      return (
        <div className={className} role="alert">
          {alert.message}
          <button type="button" className="close" onClick={this.clearAction} aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      );
    }
  }

  createNavbar() {
    return (<NavBar user={this.props.user.session} menuSelection={this.menuSelection} onMenuItemClicked={this.menuClickedItem}/>);
  }

  menuClickedItem(i) {
    const selection = this.menuSelection[i];
    if (selection.toLowerCase() === 'sign-out') this.props.logout();
  }

  pageContent() {
    // for (let p in this.props) {
    //   if (this.props.hasOwnProperty(p)) {
    //     const prop = this.props[p];
    //     if (prop && prop.constructor === Object && prop.type && prop.type.includes('BEGIN')) {
    //       return (<Loader />);
    //     }
    //   }
    // }
    switch (this.props.page) {
      case pageConstants.AI_MANAGER:
        return <AiManager />;
      case pageConstants.ACCESS_MANAGER:
        return <AccessManager />;
      case pageConstants.ACCOUNTS_MANAGER:
        return <AccountsManager />;
      case pageConstants.DOCUMENTATION:
        return <Documentation />;
      case pageConstants.SUPPORT:
        return <Support />;
      default:
       return <NotFound />;
    }
  }

  render() {
    return (
      <div className='cms wrapper'>
        <SideBar />
        { this.createNavbar() }
        <div className="cms-content">
          { this.checkAlert() }
          { this.pageContent() }
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  ...state
});

const mapDispatchToProps = {
  error: alert.error,
  clear: alert.clear,
  getSessionUser: user.Session,
  logout: authenticate.logout
};

export default connect(mapStateToProps, mapDispatchToProps)(Container);
