export const authConstants = {
  REGISTER_REQUEST: 'USER_REGISTER_BEGIN',
  REGISTER_SUCCESS: 'USER_REGISTER_SUCCESS',
  REGISTER_FAILURE: 'USER_REGISTER_FAILURE',

  LOGIN_REQUEST: 'USER_LOGIN_BEGIN',
  LOGIN_SUCCESS: 'USER_LOGIN_SUCCESS',
  LOGIN_FAILURE: 'USER_LOGIN_FAILURE',

  LOGOUT: 'USER_LOGOUT'
};
