import React, { Component } from 'react';
import PropTypes from 'prop-types';

class Input extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(e) {
    e.preventDefault();
    this.props.onChange(this.props.index, e.target.value);
  }

  render() {
    return <input
            type={this.props.type}
            className={this.props.className}
            id={this.props.id}
            aria-describedby={this.props.describedBy}
            placeholder={this.props.placeholder}
            onChange={ this.handleChange }
            value={this.props.value} />;
  }
}

Input.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string,
  index: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  describedBy: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.oneOf(['text', 'email', 'password', 'number', 'hidden']).isRequired
}

export default Input;
