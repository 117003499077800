import { blaidConstants } from '../constants';

export default (state = {}, action) => {
  switch (action.type) {
    case blaidConstants.BLAID_AUTHENTICATED:
      return { core: action.blaid };
    case blaidConstants.BLAID_DEAUTHENTICATED:
      return {};
    default:
      return state;
  }
};
