import Auth from './AuthAPI';
import Account from './AccountAPI';
import User from './UserAPI';
import Client from './ClientAPI';
import Model from './ModelAPI';
import Secret from './SecretAPI';

export default class Requests {
  constructor(options) {
    this.auth = new Auth(options);
    this.account = new Account(options);
    this.user = new User(options);
    this.client = new Client(options);
    this.model = new Model(options);
    this.secret = new Secret(options);
  }
}
