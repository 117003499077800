import BaseModel from './BaseModel';

class User extends BaseModel {

  get name() {
    return this.get('name', null);
  }

  set name(name) {
    this.set('name', name, String, null);
  }

  get account() {
    return this.get('account', null);
  }

  set account(account) {
    this.set('account', account, String, null);
  }

  get availability() {
    return this.get('availability', null);
  }

  set availability(availability) {
    this.set('availability', availability, String, null);
  }

  get enabled() {
    return this.get('enabled', null);
  }

  set enabled(enabled) {
    this.set('enabled', enabled, Boolean, null);
  }

  get endpoints() {
    return this.get('endpoints', null);
  }

  set endpoints(endpoints) {
    this.set('endpoints', endpoints, Object, null);
  }

  get ciPipeline() {
    const endpoints = this.endpoints
    return endpoints && endpoints.ci_pipeline ? endpoints.ci_pipeline : null;
  }

  set ciPipeline(endpoint) {
    const endpoints = this.endpoints
    if (endpoints && endpoint.constructor === String) {
      endpoints.ci_pipeline = endpoint;
      this.endpoints = endpoints;
    }
  }

  get cdPipeline() {
    const endpoints = this.endpoints
    return endpoints && endpoints.cd_pipeline ? endpoints.cd_pipeline : null;
  }

  set cdPipeline(endpoint) {
    const endpoints = this.endpoints
    if (endpoints && endpoint.constructor === String) {
      endpoints.cd_pipeline = endpoint;
      this.endpoints = endpoints;
    }
  }

  get jupyterNotebook() {
    const endpoints = this.endpoints
    return endpoints && endpoints.jupyter_notebook ? endpoints.jupyter_notebook : null;
  }

  set jupyterNotebook(endpoint) {
    const endpoints = this.endpoints
    if (endpoints && endpoint.constructor === String) {
      endpoints.jupyter_notebook = endpoint;
      this.endpoints = endpoints;
    }
  }
}

export default User;
