import React from 'react';
import PropTypes from 'prop-types';
import './BackButton.css';
import back from './back.svg';
import ImageButton from '../image-button';

const BackButton = (props) => {
  return <ImageButton image={back} className="back-btn" onClick={ props.onClick } alt='back' />;
}

BackButton.propTypes = {
  onClick: PropTypes.func.isRequired,
}

export default BackButton;
