import { authConstants } from '../constants';

export default (state = {}, action) => {
  const response = { type: action.type };
  switch (action.type) {
    case authConstants.LOGIN_REQUEST:
      response.message = 'Logging in...';
      return response;
    case authConstants.USER_REGISTER_REQUEST:
      response.message = 'Creating Account...';
      return response;
    case authConstants.LOGIN_SUCCESS:
      return response;
    case authConstants.REGISTER_SUCCESS:
      response.message = action.message;
      return response;
    case authConstants.LOGIN_FAILURE:
      response.message = action.message;
      return response;
    case authConstants.REGISTER_FAILURE:
      response.message = action.message;
      return response;
    case authConstants.LOGOUT:
      return response;
    default:
      return state;
  }
};
