const Methods = Object.freeze({
  GET: 'GET',
  POST: 'POST',
  PUT: 'PUT',
  PATCH: 'PATCH',
  DELETE: 'DELETE',
  HEAD: 'HEAD',
  _: {
    successCodes: {
      GET: 200,
      POST: 200,
      PUT: 200,
      PATCH: 200,
      DELETE: 200
    },
    createSuccess: {
      POST: 201,
      PUT: 201,
      PATCH: 201
    }
  }
});

const Request = (method, url, headers, body) => {
  const xhr = new XMLHttpRequest();
  return new Promise((resolve, reject) => {
    if (!(method in Methods)) {
      return reject(new Error('Invalid method supplied.'));
    }
    let successCodes = Methods._.successCodes[method];

    xhr.open(method || Methods.GET, url, true);

    if (headers && headers.constructor === Object) {
      for (let key in headers) {
        if (headers.hasOwnProperty(key)) xhr.setRequestHeader(key, headers[key]);
      }
    };

    if (body) {
      successCodes = Methods._.createSuccess[method];
      if (headers['Content-Type'] === 'application/x-www-form-urlencoded' && typeof body !== 'string') {
        return reject(new Error('Invalid body format.'));
      } else if (headers['Content-Type'].includes('application/json')) {
        if (body.constructor === Object || Array.isArray(body)) {
          body = JSON.stringify(body);
        } else if (typeof body !== 'string') {
          return reject(new Error('Invalid body format.'));
        }
      }
    };

    xhr.onreadystatechange = () => {
      if (xhr.readyState === 4) {
        if (xhr.status === successCodes) {
          try {
            return resolve(JSON.parse(xhr.response));
          } catch (e) {
            return reject(e);
          }
        }
        const error = new Error();
        error.status = xhr.status;

        if (xhr.response) {
          const response = JSON.parse(xhr.response);
          error.message = response.message;
        } else {
          error.message = xhr.statusText;
        }
        return reject(error);
      }
      return;
    };

    xhr.ontimeout = () => {
      return reject(new Error('Request timed out'));
    };

    xhr.onerror = () => {
      return reject(new Error('Request Failed'));
    };

    // xhr.onprogress = (event) => {
    //   if (event.lengthComputable) {
    //     alert(`Received ${event.loaded} of ${event.total} bytes`);
    //   } else {
    //     alert(`Received ${event.loaded} bytes`);
    //   }
    // }

    xhr.send(body);
  });
};

class HttpRequester {
  constructor(options = {}) {
    this._ = {};
    Object.keys(options).forEach(prop => {
      this._[prop] = options[prop];
    });
  }

  get host() {
    return this._.host;
  }

  set host(host) {
    if (host && host.constructor === String) {
      this._.host = host;
    }
  }

  get headers() {
    return this._.headers;
  }

  set headers(headers) {
    if (headers && headers.constructor === Object) {
      this._.headers = headers;
    } else {
      this._.headers = this.headers !== undefined ? this.headers : {};
    }
  }

  get(path, headers, Model) {
    return this._execute(Methods.GET, path, headers, Model);
  }

  post(path, headers, body, Model) {
    return this._execute(Methods.POST, path, headers, Model, body);
  }

  put(path, headers, body, Model) {
    return this._execute(Methods.PUT, path, headers, Model, body);
  }

  patch(path, headers, body, Model) {
    return this._execute(Methods.PATCH, path, headers, Model, body);
  }

  delete(path, headers) {
    return this._execute(Methods.DELETE, path, headers);
  }

  _execute(method, path, headers, Model, body) {
    return new Promise(async (resolve, reject) => {
      const self = this;
      const url = `${self.host}${path}`;
      const response = await Request(method, url, Object.assign(self.headers, headers), body).catch(reject);
      if (Model && response) {
        if (Array.isArray(response)) {
          return resolve(response.map(m => new Model(m)));
        } else if (response.constructor === Object) {
          return resolve(new Model(response));
        }
      }
      return resolve(response);
    });
  }
}

export default HttpRequester;
