import React, { Component } from 'react';
import PropTypes from 'prop-types';

class Label extends Component {
  render() {
    return <label>{this.props.text}</label>;
  }
}

Label.propTypes = {
  text: PropTypes.string.isRequired,
}

export default Label;
