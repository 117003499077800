import React, { Component } from 'react';
import {
  Input,
  BackButton
} from '../../components';
import './SignUp.css';
import { connect } from 'react-redux';
import {
  register,
  alert
} from '../../../actions';
import { withRouter } from "react-router-dom";
import { authConstants } from '../../../constants';

class SignUp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        account_name: {
          value: '',
          type: 'text'
        },
        user_name:  {
          value: '',
          type: 'text'
        },
        email:  {
          value: '',
          type: 'text'
        },
        password:  {
          value: '',
          type: 'password'
        },
        confirm_password: {
          value: '',
          type: 'password'
        },
      }
    }

    this.formElements = this.formElements.bind(this);
    this.onTextChange = this.onTextChange.bind(this);
    this.executeLogin = this.executeLogin.bind(this);
    this.onBackButtonClick = this.onBackButtonClick.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps && prevProps !== this.props) {
      const prevAuth = prevProps.auth;
      const auth = this.props.auth;
      if (prevAuth !== auth && auth.type === authConstants.REGISTER_SUCCESS) this.props.success(auth.message);
    }
  }

  onTextChange(i, val) {
    const data = this.state.data;
    const key = Object.keys(data)[i]
    data[key].value = val;
    this.setState({ data: data });
  }

  executeLogin(e) {
    e.preventDefault();
    this.props.clear();
    const data = this.state.data;
    const req = {};
    for (var d in data) {
      if (data.hasOwnProperty(d)) {
        req[d] = data[d].value;
      }
    }

    if (req.account_name && req.email && req.password && req.user_name) {
      if (req.password === req.confirm_password) {
        this.props.register(
          req.account_name,
          req.user_name,
          req.email,
          req.password);
      } else this.props.error('password and confirm password do not match.');
    } else this.props.error('Please fill in all parameters.');
  }

  onBackButtonClick(e) {
    e.preventDefault();
    this.props.history.push('/login');
  }

  formElements() {
    const data = this.state.data;
    return Object.keys(data).map((d, i) => {
      const className = `form-control ${d}`
      const title = d.replace("_", " ");
      const label = data[d].value.length > 0 ? <h5>{title}</h5>: null;
      const type = data[d].type;
      return (
        <div key={i}>
         { label }
         <div className="form-group">
            <Input
            index={i}
            type={type}
            className={className}
            id={d}
            value={data[d].value}
            placeholder={title}
            onChange={ this.onTextChange } />
          </div>
        </div>
      );
    })
  }

  render() {
    return (
      <div className='row sign-in'>
        <div className="col-md-4 offset-md-4">
          <BackButton onClick={ this.onBackButtonClick } />
          <form>
            { this.formElements() }
            <button type="submit" className="sign-up-btn" onClick={ this.executeLogin }>sign-up</button>
          </form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  ...state
});

const mapDispatchToProps = {
  register: register.createAccount,
  success: alert.success,
  error: alert.error,
  clear: alert.clear
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SignUp));
