import React, { Component } from 'react';
import { connect } from 'react-redux';
import './Documentation.css';

class Documentation extends Component {
  render() {
    return (
      <h1 className='documentation-title'>Documentation</h1>
    );
  }
}

const mapStateToProps = state => ({
  ...state
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Documentation);
