import React, {Component} from 'react';
import PropTypes from 'prop-types';
import ImageButton from '../image-button';
import ColorIcon from '../color-icon';

class MenuButton extends Component {
  constructor(props) {
    super(props);
    this.onHover = this.onHover.bind(this);
    this.offHover = this.offHover.bind(this);
    this.state = {
      hover: false
    }
  }

  onHover(e) {
    e.preventDefault();
    this.setState({hover: true});
  }

  offHover(e) {
    e.preventDefault();
    this.setState({hover: false});
  }

  render() {
    const icon = <ColorIcon {...this.props} fill={this.props.active || this.state.hover}/>;
    const label = this.state.hover ? this.props.title : null;
    return (
      <div className='menu-btn'>
        <ImageButton image={icon} type="submit" className="menu-btn" {...this.props} onMouseEnter={this.onHover} onMouseLeave={this.offHover}/>
        {label}
      </div>
    );
  }


}

PropTypes.MenuButton = {
  title: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  size: PropTypes.number.isRequired,
  color: PropTypes.string.isRequired,
  active: PropTypes.bool.isRequired,
}

export default MenuButton;
