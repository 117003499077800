import React, { Component } from 'react';
import PropTypes from 'prop-types';

class Small extends Component {
  render() {
    return <small id={this.props.id} className={this.props.className}>{this.props.text}</small>;
  }
}

Small.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  text: PropTypes.string.isRequired,
}

export default Small;
