import React, { Component } from 'react';
import './Auth.css';
import { connect } from 'react-redux';
import { alert } from '../../actions';
import {
  alertConstants,
  pageConstants
} from '../../constants';
import {
  Logo,
  Loader
} from '../components';
import SignIn from './sign-in';
import SignUp from './sign-up';

class Auth extends Component {
  constructor(props) {
    super(props);
    this.clearAction = this.clearAction.bind(this);
    this.checkAlert = this.checkAlert.bind(this);
    this.pageContent = this.pageContent.bind(this);
    this.renderBackground = this.renderBackground.bind(this);
  }

  clearAction(e) {
    e.preventDefault();
    this.props.clear();
  }

  componentDidMount() {
    this.renderBackground()
  }

  renderBackground() {
    if (document.contains(document.getElementById("particle"))) {
      document.getElementById("particle").remove();
      document.getElementById("particle-canvas").remove();
    }
    const canvas = document.createElement("canvas");
    canvas.id = 'particle-canvas';
    const script = document.createElement("script");
    script.src = "particle.js";
    script.id = 'particle';
    script.async = true;
    [canvas, script].forEach(e => document.getElementById("auth").appendChild(e));
  }

  checkAlert() {
    const alert = this.props.alert;
    if (alert.message) {
      const type = alert.type === alertConstants.SUCCESS ? 'alert-success' : 'alert-danger';
      const alertClass = `alert ${type} alert-dismissible fade show`;
      const className = `col-md-4 offset-md-4 ${alertClass}`;
      return (
        <div className={className} role="alert">
          {alert.message}
          <button type="button" className="close" onClick={this.clearAction} aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      );
    }
  }

  pageContent() {
    for (let p in this.props) {
      if (this.props.hasOwnProperty(p)) {
        const prop = this.props[p];
        if (prop && prop.constructor === Object && prop.type && prop.type.includes('BEGIN')) {
          return (<Loader />);
        }
      }
    }
    switch (this.props.page) {
      case pageConstants.SIGN_IN_PAGE:
        return <SignIn />;
      case pageConstants.SIGN_UP_PAGE:
        return <SignUp />;
      default:
        return <SignIn />;
    }
  }

  render() {
    return (
      <div className='auth' id='auth'>
        <div className='row'>
          <div className="col-md-4 offset-md-4">
            <Logo />
          </div>
        </div>
        <div className='auth-container'>
          { this.checkAlert() }
          { this.pageContent()}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  ...state
});

const mapDispatchToProps = {
  error: alert.error,
  clear: alert.clear
};

export default connect(mapStateToProps, mapDispatchToProps)(Auth);
