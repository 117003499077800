import React, { Component } from 'react';
import './App.css';
import { connect } from 'react-redux';
import {
  authConstants,
  pageConstants
} from './constants';
import {
  authenticate,
  alert
} from './actions';
import {
  Auth,
  Container
} from './pages'
import {
  withRouter,
  Route,
  Redirect
} from "react-router-dom";


class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false
    }
    this.isLoggedIn = this.isLoggedIn.bind(this);
    this.checkPreviousLogin = this.checkPreviousLogin.bind(this);
    this.handleErrors = this.handleErrors.bind(this);
    this.checkPreviousLogin();
  }

  checkPreviousLogin() {
    // localStorage.clear();
    const ssoString = localStorage.getItem('sso');
    if (ssoString) {
      const sso = JSON.parse(ssoString)
      if (sso.refresh_token) this.props.refreshLogin(sso.refresh_token)
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps && prevProps !== this.props) {
      if (prevProps.auth && prevProps.auth !== this.props.auth) {
        const auth = this.props.auth;
        if (auth.type === authConstants.LOGIN_SUCCESS) {
          return this.props.history.push('/');
        }
        return this.handleErrors();
      }
    }
  }

  handleErrors() {
    for (let p in this.props) {
      if (this.props.hasOwnProperty(p)) {
        const prop = this.props[p];
        if (prop && prop.constructor === Object && prop.type && prop.type.includes('FAILURE')) {
          return this.props.error(prop.message);
        }
      }
    }
  }

  isLoggedIn(component) {
    if (component && this.props.auth.type === authConstants.LOGIN_SUCCESS) {
      return component;
    }
    return <Redirect to="/login"/>;
  }

  render() {
    return (
      <div className='App'>
        <Route exact path='/' render={() => this.isLoggedIn(<Container />) }/>
        <Route path='/ai-manger' render={() => this.isLoggedIn(<Container page={pageConstants.AI_MANAGER} />) }/>
        <Route path='/access-manager' render={() => this.isLoggedIn(<Container page={pageConstants.ACCESS_MANAGER} />) }/>
        <Route path='/accounts-manager' render={() => this.isLoggedIn(<Container page={pageConstants.ACCOUNTS_MANAGER} />) }/>
        <Route path='/documentation' render={() => this.isLoggedIn(<Container page={pageConstants.DOCUMENTATION} />) }/>
        <Route path='/support' render={() => this.isLoggedIn(<Container page={pageConstants.SUPPORT}/>) }/>
        <Route path='/login' render={() => <Auth page={pageConstants.SIGN_IN_PAGE} />} />
        <Route path='/register' render={() => <Auth page={pageConstants.SIGN_UP_PAGE} />} />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  ...state
});

const mapDispatchToProps = {
  refreshLogin: authenticate.refreshLogin,
  error: alert.error,
  success: alert.success,
  clear: alert.clear
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
