import BaseModel from './BaseModel';

class User extends BaseModel {

  get account() {
    return this.get('account', null);
  }

  set account(account) {
    this.set('account', account, String, null);
  }

  get userName() {
    return this.get('user_name', null);
  }

  set userName(userName) {
    this.set('user_name', userName, String, null);
  }

  get firstName() {
    return this.get('first_name', null);
  }

  set firstName(firstName) {
    this.set('last_name', firstName, String, null);
  }

  get lastName() {
    return this.get('last_name', null);
  }

  set lastName(lastName) {
    this.set('last_name', lastName, String, null);
  }

  get email() {
    return this.get('email', null);
  }

  set email(email) {
    this.set('email', email, String, null);
  }

  get role() {
    return this.get('user_role', null);
  }

  set role(role) {
    this.set('user_role', role, String, null);
  }

  get enabled() {
    return this.get('enabled', null);
  }

  set enabled(enabled) {
    this.set('enabled', enabled, Boolean, null);
  }
}

export default User;
