import { authConstants } from '../constants';
import { Register } from '../services';

/**
 * REGISTER
 */
const beginRegistration = () => {
  return { type: authConstants.REGISTER_REQUEST }
}

const registrationSuccess = (message) => {
  return {
    type: authConstants.REGISTER_SUCCESS,
    message
  }
}

const registrationFailed = (message) => {
  return {
    type: authConstants.REGISTER_FAILURE,
    message
  }
}

const createAccount = (accountName, userName, email, password) => {
  return dispatch => {
    if (accountName && email && password && userName) {
      dispatch(beginRegistration());
      Register(accountName, userName, email, password)
        .then(user => dispatch(registrationSuccess(`Successfully created account and validation email is sent to ${user.email}.`)))
        .catch(e => dispatch(registrationFailed(e.message)));
    } else dispatch(registrationFailed('Account Name, User Name, E-mail and Password are required'))
  }
}

export const register = {
  createAccount
};
