import React, {Component} from 'react';
import PropTypes from 'prop-types';
import ColorIcon from '../../color-icon';
import './SideBarItem.css';

class SideBarButton extends Component {
  constructor(props) {
    super(props);
    this.onHover = this.onHover.bind(this);
    this.offHover = this.offHover.bind(this);
    this.state = {
      hover: false
    }
  }

  onHover(e) {
    e.preventDefault();
    this.setState({hover: true});
  }

  offHover(e) {
    e.preventDefault();
    this.setState({hover: false});
  }

  render() {
    const label = this.state.hover ? this.props.title : null;
    return (
      <div className='sidebar-item'>
        <button onClick={this.props.onClick} type="submit" className="sidebar-btn" onMouseEnter={this.onHover} onMouseLeave={this.offHover}>
          <ColorIcon color={this.props.color} size={this.props.size} filled={this.props.active || this.state.hover}/>
        </button>
        <p>{label}</p>
      </div>
    );
  }
}

PropTypes.SideBarButton = {
  title: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  size: PropTypes.number.isRequired,
  color: PropTypes.string.isRequired
}

export default SideBarButton;
