import React, { Component } from 'react';
import { connect } from 'react-redux';
import './Support.css';

class Support extends Component {
  render() {
    return (
      <h1 className='support-title'>Support</h1>
    );
  }
}

const mapStateToProps = state => ({
  ...state
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Support);
