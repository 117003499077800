
const host = process.env.NODE_ENV === 'development' ? 'http://localhost:8000' : 'https://api.blaid.io';

const config = Object.freeze({
  host: host,
  apiVersion: 1,
  clientId: '5d45ef63c009202325e0b53e'
});

export default config;
