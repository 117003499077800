import {
  authConstants,
  blaidConstants
} from '../constants';
import {
  EmailLogin,
  RefreshBlaid,
  Logout
} from '../services';

/**
 * STATES
 */
const beginLogin = () => {
  return { type: authConstants.LOGIN_REQUEST }
}

const loginSuccess = () => {
  return { type: authConstants.LOGIN_SUCCESS }
}

const loginFailed = (message) => {
  return {
    type: authConstants.LOGIN_FAILURE,
    message
  }
}

const coreCreated = (blaid) => {
  return {
    type: blaidConstants.BLAID_AUTHENTICATED,
    blaid
  }
}

const coreRelease = () => {
  return {
    type: blaidConstants.BLAID_DEAUTHENTICATED
  }
}

/**
 * METHODS
 */
const emailLogin = (email, password) => {
  return async dispatch => {
    dispatch(beginLogin());
    const blaid = EmailLogin(email, password).catch(e => dispatch(loginFailed(e.message)));
    dispatch(coreCreated(blaid));
    return dispatch(loginSuccess());
  }
}

const refreshLogin = (token) => {
  return async dispatch => {
    dispatch(beginLogin());
    const blaid = await RefreshBlaid(token).catch(e => dispatch(loginFailed(e.message)));
    dispatch(coreCreated(blaid));
    return dispatch(loginSuccess());
  }
}

const logout = () => {
  return dispatch => {
    Logout();
    dispatch(coreRelease());
    return dispatch({ type: authConstants.LOGOUT });
  }
}

export const authenticate = {
  emailLogin,
  refreshLogin,
  logout
};
