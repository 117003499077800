import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './Navbar.css'
import Logo from '../logo';
import SettingsButton from '../settings-button';

class NavBar extends Component {
  render() {
    const name = this.props.user ? <h4>{this.props.user.userName}</h4> : null;
    return (
      <nav className="navbar container-fluid-nav text-center">
        <Logo className="nav-logo"/>
        { name }
        <SettingsButton selections={this.props.menuSelection} onSelectionClicked={this.props.onMenuItemClicked} />
      </nav>
    );
  }
}

NavBar.propTypes = {
  user: PropTypes.object,
  menuSelection: PropTypes.array.isRequired,
  onMenuItemClicked: PropTypes.func.isRequired,
}

export default NavBar;
