import BaseModel from './BaseModel';

class User extends BaseModel {

  get name() {
    return this.get('name', null);
  }

  set name(name) {
    this.set('name', name, String, null);
  }

  get account() {
    return this.get('account', null);
  }

  set account(account) {
    this.set('account', account, String, null);
  }

  get secret() {
    return this.get('secret', null);
  }

  set secret(secret) {
    this.set('secret', secret, String, null);
  }

  get accessLevel() {
    return this.get('access_level', null);
  }

  set accessLevel(accessLevel) {
    this.set('access_level', accessLevel, String, null);
  }

  get enabled() {
    return this.get('enabled', null);
  }

  set enabled(enabled) {
    this.set('enabled', enabled, Boolean, null);
  }
}

export default User;
