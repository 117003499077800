import React from 'react';
import PropTypes from 'prop-types';

const Iframe = (props) => {
  const className = `embed-responsive-item ${props.className}`
  return <iframe title={props.title} className={className} src={props.src}/>;
}

Iframe.propTypes = {
  src: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired
}

export default Iframe;
