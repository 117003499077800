import Model from './models';
import Requests from './requests';

class BLAID {
  constructor(options) {
    this.models = new Model();
    this.requests = new Requests(options);
  }

  static authenticate(host, username, password, clientId) {
    return new Promise(async (resolve, reject) => {
      const blaid = new BLAID({host, headers: {}});
      const auth = blaid.requests.auth;
      // Authenticate
      const r = await auth.authPassword(username, password, clientId)
        .catch(e => {
          localStorage.clear();
          return reject(e);
        });
      localStorage.setItem('sso', JSON.stringify(r));
      return resolve(new BLAID({
        host,
        headers: {
          Authorization: `Bearer ${r.access_token}`
        }
      }));
    });
  }

  static sso(host, clientId, token) {
    return new Promise(async (resolve, reject) => {
      const blaid = new BLAID({host, headers: {}});
      const auth = blaid.requests.auth;

      if (!token) {
        const secret = await blaid.requests.secret.get(clientId).catch(reject);
        token = secret.client_secret;
      }
      // Authenticate
      const r = await auth.authToken(clientId, token)
        .catch(e => {
          localStorage.clear();
          return reject(e);
        });

        return resolve(new BLAID({
          host,
          headers: {
            Authorization: `Bearer ${r.access_token}`
          }
        }));
    });
  }

  static refresh(host, token) {
    return new Promise(async (resolve, reject) => {
      const blaid = new BLAID({host, headers: {}});
      const auth = blaid.requests.auth;
      // Authenticate
      const r = await auth.refreshToken(token)
        .catch(e => {
          localStorage.clear();
          return reject(e);
        });
      localStorage.setItem('sso', JSON.stringify(r));
      return resolve(new BLAID({
        host,
        headers: {
          Authorization: `Bearer ${r.access_token}`
        }
      }));
    });
  }
}

((_export) => {
  try {
    window.BLAID = _export;
  } catch (e) {
    module.exports = _export;
  }
})(BLAID);

export default BLAID;
