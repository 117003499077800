import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './SettingsButton.css';
import menu from './menu.svg';
import ImageButton from '../image-button';

class SettingsButton extends Component {
  constructor(props) {
    super(props);
    this.createSelection = this.createSelection.bind(this);
    this.onMenuItemClicked = this.onMenuItemClicked.bind(this);
  }

  createSelection() {
    if (this.props.selections) {
      return (
        <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
          {
            this.props.selections.map((s, i) => {
              return <button key={i} type='button' className="dropdown-item" onClick={(e) => this.onMenuItemClicked(e, i)}>{s}</button>
            })
          }
        </div>
      );
    }
  }

  onMenuItemClicked(e, i) {
    e.preventDefault();
    this.props.onSelectionClicked(i);
  }

  render() {
    return (
      <div className="dropdown">
        <ImageButton image={menu}
        type="submit"
        className="settings-btn"
        id="dropdownMenuButton" 
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
        alt='settings' />
        { this.createSelection() }
      </div>
    );
  }
}

SettingsButton.propTypes = {
  selections: PropTypes.array.isRequired,
  onSelectionClicked: PropTypes.func.isRequired,
}

export default SettingsButton;
