import HttpRequester from '../utils/http';

class SecretAPI extends HttpRequester {

  get(id, headers = {}) {
    return new Promise(async (resolve, reject) => {
      if (!id) return reject('Client ID is required');
      const secret = await super.get(`/secret/${id}`, headers).catch(reject);
      return resolve(secret);
    });
  }
}

export default SecretAPI;
