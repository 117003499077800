import { combineReducers } from 'redux';
import BLAID from './blaid.reducer.js';
import alert from './alert.reducer.js';
import auth from './auth.reducer.js';
import account from './account.reducer.js';
import user from './user.reducer.js';
import client from './client.reducer.js';
import model from './model.reducer.js';

export default combineReducers({
  BLAID,
  alert,
  auth,
  account,
  user,
  client,
  model
});
