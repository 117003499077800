import React, { Component } from 'react';
import './Sidebar.css';
import SideBarItem from './sidebar-item';
import MenuData from './sidebar-menu-data';
import { withRouter } from "react-router-dom";

class SideBar extends Component {

  constructor(props) {
    super(props);
    this.state = {
      showMenu: false,
      selections: MenuData
    }
    this.toggleMenu = this.toggleMenu.bind(this);
    this.onSelect = this.onSelect.bind(this);
  }

  toggleMenu() {
    this.setState({showMenu: !this.state.showMenu});
  }

  onSelect(e, i) {
    e.preventDefault();
    const select = this.state.selections[i];
    this.props.history.push(select.path);
  }

  sidebarItems() {
    return this.state.selections.map((item, i) => {
      const active = this.props.location.pathname === item.path;
      return (
        <li key={i}>
          <SideBarItem
          {...item}
          active={active}
          size={60}
          onClick={e => this.onSelect(e, i)} />
        </li>
      );
    });
  }

  render() {
    return (
      <div className='sidebar'>
        <ul className="list-unstyled">
          { this.sidebarItems() }
        </ul>
      </div>
    );
  }
}

export default withRouter(SideBar);
