import config from '../config';
import { BLAID } from '../lib';

const EmailLogin = (email, password) => {
  return BLAID.authenticate(`${config.host}/v${config.apiVersion}`, email, password, config.clientId);
}

const RefreshBlaid = async (token) => {
  return BLAID.refresh(`${config.host}/v${config.apiVersion}`, token);
}

const Logout = () => {
  localStorage.clear();
  sessionStorage.clear();
}

export {
  EmailLogin,
  RefreshBlaid,
  Logout
}
