import React, { Component } from 'react';
import { connect } from 'react-redux';
import './AiManager.css';
import {
  alert,
  model
} from '../../../../actions';
import { Iframe } from '../../../components';

class AiManager extends Component {
  constructor(props) {
    super(props);
    props.getModel();
    this.getTitle = this.getTitle.bind(this);
    this.modelList = this.modelList.bind(this);
    this.selectedSubOption = this.selectedSubOption.bind(this);
    this.content = this.content.bind(this);
    this.renderSubFrame = this.renderSubFrame.bind(this);

    this.state = {
      modelName: ''
    }
  }

  selectedSubOption(e, i, j) {
    e.preventDefault();
    const models = this.props.model.models;
    const model = models[i];
    const endpoint = Object.keys(model.endpoints)[j];
    const url = model.endpoints[endpoint];
    window.open(url, '_blank');
    // const subtitle = endpoint.replace('_', " ").split(' ').map( w =>  w.substring(0,1).toUpperCase()+ w.substring(1)).join(' ');
    // this.setState({
    //   modelName: model.name,
    //   subtitle: subtitle,
    //   endpointUrl: url,
    // })
  }

  modelList() {
    const models = this.props.model.models;
    if (Array.isArray(models)) {
      return (
        <div className="list-group">
          {
            models.map((m, i) => {
              return (
                <div key={i} className="list-group-item list-group-item-action flex-column align-items-start">
                  <div className="d-flex w-100 justify-content-between">
                    <p className="mb-1">{m.name}</p>
                    <small>{m.enabled ? 'Active' : 'Disabled'}</small>
                  </div>
                  {
                    Object.keys(m.endpoints).map((e, j) => {
                      if (m.endpoints[e] !== undefined && m.endpoints[e] !== '') {
                        const title = e.replace('_', " ").split(' ').map( w =>  w.substring(0,1).toUpperCase()+ w.substring(1)).join(' ');
                        return <button
                        key={j}
                        type="button"
                        className="btn btn-primary"
                        onClick={(e) => this.selectedSubOption(e, i, j)}>{title}</button>
                      }
                      return null;
                    })
                  }
                </div>
              )
            })
          }
        </div>
      )
    }
  }

  renderSubFrame() {
    return (
      <div className='ai-view-port'>
        <h3 className='ai-manager-sub-title'>{this.state.subtitle}</h3>
        <Iframe title={this.state.subtitle} src={this.state.endpointUrl} className='ai-content' />
      </div>
    )
  }

  getTitle() {
    const name = this.state.modelName;
    const title = name !== '' ? `AI Manager - ${name}` : 'AI Manager'
    return <h1 className='ai-manager-title'>{title}</h1>
  }

  content() {
    if (this.state.modelName !== undefined && this.state.modelName !== '') {
      return this.renderSubFrame();
    } else {
      return this.modelList();
    }
  }

  render() {
    return (
      <div className='ai-manager-content'>
        { this.getTitle() }
        { this.content() }
      </div>
    );
  }
}

const mapStateToProps = state => ({
  ...state
});

const mapDispatchToProps = {
  getModel: model.Get,
  createModel: model.Create,
  updateModel: model.Update,
  deleteModel: model.Delete,
  clear: alert.clear
};

export default connect(mapStateToProps, mapDispatchToProps)(AiManager);
