import React from 'react';
import PropTypes from 'prop-types';
import './ColorIcon.css';

const ColorIcon = (props) => {
  const center = props.size / 2;
  const r = center - 10;
  const fill = props.filled ? props.color : 'transparent';
  return (
    <svg height={props.size} width={props.size}>
      <circle cx={center} cy={center} r={r} stroke={props.color} strokeWidth='3' fill={fill} />
    </svg>
  );
}

ColorIcon.propTypes = {
  size: PropTypes.number.isRequired,
  color: PropTypes.string.isRequired,
  fill: PropTypes.bool,
}

export default ColorIcon;
