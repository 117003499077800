import config from '../config';
import { BLAID } from '../lib';

/**
 * Client
 */
const SuperClient = () => {
  return new Promise(async (resolve, reject) => {
    const { superClient } = window;
    if (superClient) resolve(superClient);
    else {
      const cl = await BLAID.sso(`${config.host}/v${config.apiVersion}`, config.clientId).catch(reject);
      window.superClient = cl;
      return resolve(cl);
    }
  });
}

const ClearSuperClient = () => {
  window.superClient = null;
}

/**
 * Registration
 */
const RegisterAccount = (name) => {
  return new Promise(async (resolve, reject) => {
    const blaid = await SuperClient().catch(reject);
    const acc = await blaid.requests.account.create(new blaid.models.account({ name: name }))
      .catch(e => {
        ClearSuperClient();
        return reject(e);
      });
    return resolve(acc);
  });
}

const DeleteAccount = (account, errMessage) => {
  return new Promise(async (resolve, reject) => {
    const blaid = await SuperClient()
      .catch(e => {
        ClearSuperClient();
        return reject(e);
      });
    await blaid.requests.account.delete(account).catch(reject);
    ClearSuperClient();
    return reject(errMessage);
  });
}

const RegisterUser = (account, userName, email, password) => {
  return new Promise(async (resolve, reject) => {
    const blaid = await SuperClient().catch(reject);
    const newUser = await blaid.requests.user.create(new blaid.models.user({
      email: email,
      password: password,
      user_name: userName
    }), account).catch(reject);
    return resolve(newUser);
  });
}

const Register = (accountName, userName, email, password) => {
  return new Promise((resolve, reject) => {
    RegisterAccount(accountName)
      .then(acc => {
        RegisterUser(acc, userName, email, password)
          .then(resolve)
          .catch(reject);

      })
      .catch(reject);
  });
}

export { Register }
