import { clientConstants } from '../constants';
import {
  Core,
  SessionAccount
} from '../services';

/**
 * STATES
 */
const beginRequest = (message) => {
  return {
    type: clientConstants.REQUEST_BEGIN,
    message
  }
}

const getSuccess = (clients) => {
  return {
    type: clientConstants.GET_SUCCESS,
    clients
  }
}

const createSuccess = (client) => {
  return {
    type: clientConstants.CREATE_SUCCESS,
    client
  }
}

const updateSuccess = (client) => {
  return {
    type: clientConstants.UPDATE_SUCCESS,
    client
  }
}

const deleteSuccess = () => {
  return { type: clientConstants.DELETE_SUCCESS }
}

const onFailure = (message) => {
  return {
    type: clientConstants.REQUEST_FAILED,
    message
  }
}

/**
 * METHODS
 */
const Get = (id) => {
  return async (dispatch, getState) => {
    if (id) dispatch(beginRequest('Retrieving Client'));
    else dispatch(beginRequest('Retrieving Clients'));
    const state = getState();
    const core = await Core(state).catch(e => dispatch(onFailure(e.message)));
    const account = await SessionAccount(state).catch(e => dispatch(onFailure(e.message)));
    const clients = await core.requests.client.get(account, id).catch(e => dispatch(onFailure(e.message)));
    return dispatch(getSuccess(clients));
  }
}

const Create = (data) => {
  return async (dispatch, getState) => {
    dispatch(beginRequest('Creating Client'));
    const state = getState();
    const core = await Core(state).catch(e => dispatch(onFailure(e.message)));
    const account = await SessionAccount(state).catch(e => dispatch(onFailure(e.message)));
    const newClient = new core.models.client(data);
    const client = await core.requests.client.create(newClient, account).catch(e => dispatch(onFailure(e.message)));
    return dispatch(createSuccess(client));
  }
}

const Update = (data, client) => {
  return async (dispatch, getState) => {
    dispatch(beginRequest('Updating Client'));
    const state = getState();
    const core = await Core(state).catch(e => dispatch(onFailure(e.message)));
    const update = client.update(Object.assign(client.export(), data));
    const account = await SessionAccount(state).catch(e => dispatch(onFailure(e.message)));
    const updateClient = await core.requests.client.update(update, account).catch(e => dispatch(onFailure(e.message)));
    return dispatch(updateSuccess(updateClient));
  }
}

const Delete = (client) => {
  return async (dispatch, getState) => {
    dispatch(beginRequest('Deleting Client'));
    const state = getState();
    const core = await Core(state).catch(e => dispatch(onFailure(e.message)));
    const account = await SessionAccount(state).catch(e => dispatch(onFailure(e.message)));
    await core.requests.client.delete(client, account).catch(e => dispatch(onFailure(e.message)));
    return dispatch(deleteSuccess());
  }
}

export const client = {
  Get,
  Create,
  Update,
  Delete
};
